<template>
    <div class="send-coupon">
        <section class="send-coupon__heading">
            <h1 class="send-coupon__heading-h1">¿Quieres enviar un cupón?</h1>
            <h2 class="send-coupon__heading-h2">Comparte un cupón u oferta que hayas visto con nosotros</h2>
        </section>

        <article class="send-coupon-article">
            <form class="send-coupon-form" @submit.prevent="onSubmit">
                <div class="input-container">
                    <label class="input-container__label" for="website"> Sitio web </label>
                    <input
                        class="input-container__input"
                        type="text"
                        name="website"
                        placeholder="Por ej. www.amazon.com"
                        v-model="website"
                    />
                </div>
                <div class="input-container">
                    <label class="input-container__label" for="code"> Código de cupón </label>
                    <input
                        class="input-container__input"
                        type="text"
                        name="code"
                        placeholder="Por ej. CUPON10"
                        v-model="code"
                    />
                </div>
                <div class="input-container">
                    <label class="input-container__label" for="description"> Describe la oferta </label>
                    <textarea
                        class="input-container__input input-container__input--textarea"
                        name="description"
                        placeholder="Obtén un 10% de descuento aplicando este código en tu compra"
                        rezize="none"
                        v-model="description"
                    ></textarea>
                </div>
                <div class="input-container">
                    <label class="input-container__label input-container__label--date" for="expiration">
                        Fecha de expiración</label
                    >
                    <input
                        class="input-container__input"
                        type="date"
                        name="expiration"
                        placeholder="mm/dd/yyyy"
                        v-model="expiration"
                    />
                </div>

                <p class="send-coupon-form__required-message">Indica que el campo es requerido</p>

                <button :disabled="!isFormFullfilled" class="send-coupon-form__submit" type="submit">
                    <img v-if="isLoading" :src="$assets.white.loading" alt="icon" class="spin h-4 w-4" />
                    {{ isLoading ? 'Enviando...' : 'Enviar Cupón' }}
                </button>

                <NuxtTurnstile v-model="turnstileVerified" />
            </form>
        </article>

        <article class="send-coupon-faq">
            <h2 class="send-coupon-faq__title">FAQ:</h2>
            <template v-for="(item, index) in faq[`${$config.public.variant.value}`]" :key="index">
                <details class="send-coupon-faq__details" ref="detailsRef">
                    <summary class="send-coupon-faq__summary">
                        {{ item.question }}

                        <img
                            :src="$assets.gray.arrowDown"
                            class="send-coupon-faq__summary-icon"
                            alt="flecha abajo"
                        />
                    </summary>
                    <p class="send-coupon-faq__answer">{{ item.answer }}</p>
                </details>
            </template>
        </article>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~~/store/root'
import type { Api } from '~~/global'
import { replacePlaceholders } from '~~/util/textFunction'

const { buildHeaders, baseUrl, endpoints } = useApiClient()
const { setSiteNotification } = useRootStore()

const { currentDate } = useDateFunctions()

const currentDates = currentDate()

const RootStore = useRootStore()

const detailsRef = ref<HTMLDetailsElement[]>([])

const turnstileVerified = ref()

const isLoading = ref(false)

const website = ref('')
const code = ref('')
const description = ref('')
const expiration = ref('')

watch(turnstileVerified, (val) => {
    console.log(val)
})

interface FAQItem {
    question: string
    answer: string
}

interface FAQ {
    [key: string]: FAQItem[]
}

const faq: FAQ = {
    megadescuentos: [
        {
            question: '¿Dónde puedo publicar un código promocional que encontré en la red?',
            answer: 'Puedes compartir códigos de descuento, cupones y ofertas en  nuestro sitio. Cuando nos mandes un cupón u oferta, lo verificaremos y si funciona, lo publicaremos. Muchas gracias por tu colaboración.',
        },
        {
            question: '¿Cómo puedo publicar una oferta en vuestra plataforma?',
            answer: 'Para publicar un código de descuento en nuestra plataforma, solo ingresa a esta sección y agrega el código, el porcentaje de descuento (%, $, Envío Gratis, 2x1, etc.) y la tienda. Es muy fácil y rápido.',
        },
        {
            question: '¿Puedo enviar cupones de mi tienda en línea?',
            answer: 'Puedes publicar cupones, códigos promocionales y ofertas de cualquier tienda en línea de forma gratuita en nuestro sitio. Si tienes un cupón, envíalo y lo intentaremos publicar.',
        },
        {
            question: '¿Cómo encuentro códigos promocionales?',
            answer: 'En nuestra web puedes encontrar miles de códigos de descuento que realmente funcionan. Buscamos en internet diariamente para ofrecerte los mejores cupones verificados. Llevamos casi 3 años haciendo búsquedas de cupones todos los días.',
        },
    ],
    jdescuentos: [
        {
            question: '¿Dónde puedo publicar un código promocional que encontré en la red?',
            answer: 'En Jdescuentos puedes compartir cualquier código de descuento, cupón u oferta. Simplemente envíanos el cupón, lo verificamos y, si funciona, lo publicamos. Gracias por tu colaboración.',
        },
        {
            question: '¿Cómo puedo publicar una oferta en vuestra plataforma?',
            answer: 'Para agregar un código de descuento, entra en la sección correspondiente, añade el código, especifica el tipo de descuento (%, $, Envío Gratis, 2x1, etc.) y selecciona la tienda. Es un proceso sencillo y rápido.',
        },
        {
            question: '¿Puedo enviar cupones de mi tienda en línea?',
            answer: 'Puedes enviar cupones, códigos promocionales y ofertas de cualquier tienda en línea de forma gratuita. Si tienes un cupón, envíalo y lo revisaremos para publicarlo.',
        },
        {
            question: '¿Cómo encuentro códigos promocionales?',
            answer: 'En Jdescuentos encontrarás miles de códigos de descuento verificados. Realizamos búsquedas diarias para ofrecerte las mejores promociones, respaldados por casi 3 años de experiencia.',
        },
    ],
    cupohosting: [
        {
            question: '¿Dónde puedo compartir un cupón o código promocional que encontré?',
            answer: 'En Cupohosting puedes compartir cualquier código de descuento, cupón u oferta que encuentres. Envía el cupón y, tras verificarlo, lo publicaremos. Gracias por colaborar.',
        },
        {
            question: '¿Cómo puedo publicar una oferta en la plataforma?',
            answer: 'Para agregar un código de descuento, accede a la sección de envío de códigos, ingresa el código, selecciona el tipo de descuento (%, $, Envío Gratis, 2x1, etc.) y elige la tienda. El proceso es rápido y sencillo.',
        },
        {
            question: '¿Puedo enviar cupones de mi tienda online?',
            answer: 'Puedes enviar cupones, códigos promocionales y ofertas de cualquier tienda en línea sin coste alguno. Si cuentas con un cupón, mándalo y lo revisaremos para publicarlo.',
        },
        {
            question: '¿Dónde encuentro los mejores códigos promocionales?',
            answer: 'En Cupohosting dispondrás de miles de códigos de descuento verificados. Realizamos búsquedas diarias para ofrecerte las mejores promociones, respaldados por nuestra experiencia en el sector.',
        },
    ],
    bchollos: [
        {
            question: '¿Dónde puedo compartir un código promocional descubierto en la web?',
            answer: 'En Bchollos puedes compartir cualquier código promocional, cupón u oferta que encuentres. Envía el cupón y, tras verificarlo, lo publicaremos. Agradecemos tu aporte.',
        },
        {
            question: '¿Cómo puedo añadir una oferta en vuestra plataforma?',
            answer: 'Para publicar un código de descuento, ingresa a la sección correspondiente, añade el código, selecciona el tipo de descuento (%, $, Envío Gratis, 2x1, etc.) y elige la tienda. El proceso es fácil y rápido.',
        },
        {
            question: '¿Es posible enviar cupones de mi tienda en línea?',
            answer: 'Puedes enviar cupones, códigos promocionales y ofertas de cualquier tienda en línea de forma gratuita. Si tienes un cupón, mándalo y lo revisaremos para su publicación.',
        },
        {
            question: '¿Cómo localizo códigos promocionales efectivos?',
            answer: 'En Bchollos encontrarás miles de códigos de descuento verificados. Realizamos búsquedas diarias para ofrecerte las mejores promociones, respaldados por casi 3 años de experiencia.',
        },
    ],
}

onMounted(() => {
    detailsRef.value.forEach((item) => {
        item.addEventListener('click', () => {
            detailsRef.value.forEach((detail) => {
                if (detail !== item) {
                    detail.removeAttribute('open')
                }
            })
        })
    })
})

const isFormFullfilled = computed(() => {
    return !!website.value.length && !!code.value.length && !!description.value.length
})

const onSubmit = async () => {
    if (
        !turnstileVerified.value ||
        typeof turnstileVerified.value !== 'string' ||
        turnstileVerified.value.length === 0
    ) {
        RootStore.setSiteNotification({
            text: 'Compruebe que no es un robot',
            type: 'warning',
            duration: 8000,
        })
        return
    }

    const bodyFormData = {
        url: website.value,
        code: code.value,
        description: description.value,
        expired_at: expiration.value || '',
    }

    if (isFormFullfilled.value) {
        isLoading.value = true
        try {
            const response = await $fetch<
                Api.Responses.Info.SuccessSendCoupon | Api.Responses.Info.FailedSendCoupon
            >(endpoints.info.sendCoupon.path, {
                method: 'POST',
                body: bodyFormData,
                headers: buildHeaders(),
                baseURL: baseUrl,
            })
            if (response.feedback === 'resource_created') {
                setSiteNotification({
                    duration: 5000,
                    text: 'Tu Cupón ha sido enviado correctamente',
                    type: 'success',
                })
                website.value = ''
                code.value = ''
                description.value = ''
                expiration.value = ''
            }
            isLoading.value = false
        } catch (error) {
            setSiteNotification({
                duration: 5000,
                text: 'Sucedió un error, por favor, intentelo nuevamente mas tarde.',
                type: 'error',
            })
            isLoading.value = false
        }
    }
}

const seoContent = {
    megadescuentos: {
        title: 'Enviar cupón | :site',
        description: 'Envía tu cupón y lo publicaremos en nuestra web lo antes posible.',
    },
    bchollos: {
        title: 'Enviar código descuento | :site',
        description: 'Envía un código de descuento que hayas visto o tengas y lo publicaremos en Bchollos.',
    },
    jdescuentos: {
        title: 'Enviar cupón de descuento | :site',
        description: 'Envía tu cupón de descuento, lo revisaremos y si funciona lo publicaremos.',
    },
    cupohosting: {
        title: 'Enviar código promocional | :site',
        description:
            'Si quieres enviar un código promocional para que lo publiquemos, rellena el formulario.',
    },
}

const Route = useRoute()
const {
    public: {
        variant: {
            value,
            metaData: { siteOrigin, siteName },
        },
    },
} = useRuntimeConfig()

const variantSeoContent = seoContent[value as keyof typeof seoContent]

const [SeoTitle, SeoDescription] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [variantSeoContent.title || '', variantSeoContent.description || ''],
)

useSeoMeta({
    title: SeoTitle,
    ogUrl: siteOrigin + Route.path,
    ogTitle: SeoTitle,
    description: SeoDescription,
    ogDescription: SeoDescription,
    ogImageAlt: SeoTitle,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.send-coupon {
    @apply space-y-[25px] pb-[35px] pt-5 md:pb-[45px];

    &__heading {
        @apply flex flex-col items-center gap-y-2 text-center;

        &-h1 {
            @apply text-center text-[28px] font-medium md:text-[32px];
        }

        &-h2 {
            @apply text-center text-2xl lg:text-[28px];
        }
    }
    &-article {
        @apply w-full space-y-[25px] bg-white lg:mx-auto lg:w-[550px] lg:rounded-xl;
    }

    &-form {
        @apply m-auto flex w-[300px] flex-col gap-y-[15px] py-[35px];

        & .input-container {
            @apply flex flex-col gap-y-[5px];

            &__label {
                @apply relative text-xs font-semibold after:absolute after:text-red-500 after:content-['*'];

                &--date {
                    @apply after:ml-1 after:text-[10px] after:font-normal after:text-black after:content-['(Opcional)'];
                }
            }

            &__input {
                @apply rounded-xl border border-gray-300 border-transparent bg-gray-100 p-3 text-sm placeholder:text-gray-400 focus:ring-site-primary lg:hover:ring-site-primary;

                &--textarea {
                    @apply h-[100px] resize-none;
                }
            }
        }

        &__required-message {
            @apply relative text-[10px] before:text-red-500 before:content-['*'];
        }

        &__submit {
            @apply flex items-center justify-center gap-x-2 rounded-xl bg-site-primary px-[40px] py-[15px] uppercase text-site-primary-text-contrast hover:bg-site-primary-hover disabled:cursor-not-allowed disabled:opacity-70 disabled:hover:bg-site-primary;
        }
    }

    &-faq {
        @apply container max-w-[930px] px-5;
        &__title {
            @apply mb-[25px] text-center text-[22px] font-semibold;
        }

        &__details {
            @apply mb-[5px] rounded-xl bg-white px-[35px] py-[25px] md:px-[45px] md:py-[35px];
        }

        &__summary {
            @apply flex cursor-pointer items-center gap-x-[15px] rounded text-base font-semibold md:text-[22px];

            &-icon {
                @apply ml-auto size-5;
            }
        }

        &__answer {
            @apply rounded-b pt-[5px] text-sm text-site-gray md:px-[25px] md:pt-[15px] md:text-base;
        }
    }
}

.send-coupon-faq__details[open] .send-coupon-faq__summary-icon {
    @apply rotate-180;
}
</style>
